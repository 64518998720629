.screen{
    padding-inline: 20px;
}

.css-19kzrtu{
    padding-inline: 0 !important;
    padding-bottom: 0 !important;
}

.tab-title{
    padding-inline: 12px !important;
    font-size: max(13px,0.95vw) !important; 
}

.MuiIconButton-root{
  background-color: var(--background-filter) !important;
}

.MuiPickersLayout-root{
  background-color: var(--background-filter) !important;
}

.Mui-selected{
  color: var(--text-color) !important;
}

.custom-filter{
  background-color: var(--background-filter) !important;
  color: var(--text-color) !important;
  width: 100% !important;
  border-radius: 15px;
}

.custom-filter-local{
  background-color: var(--background-filter) !important;
  color: var(--text-color) !important;
  width: 100% !important;
  max-width: 500px !important;
  border-radius: 15px;
}

.MuiChip-root, .MuiChip-deleteIcon{
  background-color: var(--background-filter) !important; /* Colore di sfondo del menu delle opzioni */
  color: var(--text-color) !important; /* Colore del testo delle opzioni */
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  background-color: transparent !important;
}

.MuiButtonBase-root,.MuiMenuItem-root ,.MuiMenuItem-gutters ,.Mui-selected ,.MuiMenuItem-root ,.MuiMenuItem-gutters ,.Mui-selected ,.MuiMultiSectionDigitalClockSection-item ,.css-m6c45j-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item
{  background-color: transparent !important; /* Colore di sfondo del menu delle opzioni */
  color: var(--text-color); /* Colore del testo delle opzioni */
}

.MuiPaper-root, .MuiAutocomplete-paper, .MuiAutocomplete-noOptions, .MuiAutocomplete-listbox, .MuiDateCalendar-root {
  color: var(--text-color); /* Colore del testo delle opzioni */
}

.MuiCardContent-root{
  background-color: var(--background-card) !important; 
}

.button-query{
    position: relative;
    width: 100% !important;
    border-radius: 15px;
    font-family: 'Custom_font';
    font-weight: bold;
    font-size: 1.0rem !important;
    height: 56px;
    position: relative;
    border: 1px solid grey;
    background-color: transparent;
    color: var(--text-color);
    padding-block: 10px;
}

.button-query:hover, .button-query-small:hover {
    background-color: white;
    color: var(--red-element);
}

.button-query:active, .button-query-small:active {
    background-color: var(--red-element);
    color: var(--text-color);
}

.css-1l8c7h6-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input{
  min-width: 0 !important;
}

.skeleton{
    border-radius: 15px;
    background-color: var(--background-card) !important;
}

.title-card{
    margin: 0;
    color: var(--text-color);
    font-size: min(1.8vw,25px)
}

.text-card{   
    margin: 0;
    color: var(--text-color);
    font-size: min(2.5vw,35px)
}

.score-card-item{
    padding:5px;    
    border-radius: 15px;
    width: auto;
    margin-bottom: 10px;
}

.data-filter .MuiOutlinedInput-root .MuiInputBase-input {
  color: white !important; /* Colore del testo all'interno dell'input */
  font-weight: bold !important; /* Grassetto per il testo */
}

.data-filter .MuiOutlinedInput-root fieldset {
  border-color: gray !important; /* Colore del bordo normale */
}

.data-filter .MuiOutlinedInput-root:hover fieldset {
  border-color: white !important; /* Colore del bordo al passaggio del mouse */
}

.data-filter .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: white !important; /* Colore del bordo quando è in focus */
}

.data-filter .MuiInputLabel-root {
  font-weight: bold !important; /* Grassetto per le etichette */
  color: white !important; /* Colore bianco per le etichette */
}

.data-filter .MuiInputLabel-root.Mui-focused {
  color: white !important; /* Colore bianco quando l'etichetta è in focus */
}

.data-filter .MuiInputLabel-root.Mui-disabled {
  color: black !important; /* Colore nero per le etichette disabilitate */
}

.css-1pp108k-MuiDivider-root, .css-1pobsuk-MuiDivider-root, .css-dyllp1-MuiList-root {
  border-color: transparent !important
}

.css-1p3levo-MuiPaper-root-MuiPopover-paper-MuiMenu-paper, .css-19gh5uy-MuiPaper-root-MuiAutocomplete-paper{
  background-color: var(--background-filter) !important;
  background-image:none !important;
}

/* Bordo normale del Select */
.select {
  background-color: var(--background-filter) !important;
  margin-block: 5px !important;
  border-color: gray !important;  
  width: 150px;
}

/* Bordo al passaggio del mouse */
.select:hover .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

/* Bordo quando l'elemento è attivo (focus) */
.select .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: gray !important;
}

/* Colore del testo all'interno del Select */
.select .MuiOutlinedInput-input {
  color: white !important;
  font-weight: bold; /* Testo in grassetto */
}

/* Colore dell'icona freccia e sfondo trasparente */
.select .MuiSvgIcon-root {
  color: white !important;
  background-color: transparent !important;
}

/* Colore e stile dell'etichetta */
.select .MuiInputLabel-root {
  color: white  !important;
  font-weight: bold;
}

/* Colore e stile dell'etichetta quando il Select è attivo */
.select .MuiInputLabel-root.Mui-focused {
  color: white !important;
  font-weight: bold;
}

.css-46bh2p-MuiCardContent-root:last-child{
  padding-bottom: 25px !important;
}

.css-46bh2p-MuiCardContent-root{
  padding: 25px !important;
}