.chart-container {
  height: 400px;
  width: 100%;
  overflow-y: auto;
}

.chart-wrapper {
  /* min-width: 800px;  */
}

.graph-title{
  font-size: max(1.6vw,20px) !important;
  text-align: start;
  height: 100%;
  color: var(--text-color);
  font-weight: bold !important;
}

.graph-quality-title{
  font-size: max(1.3vw,20px) !important;
  text-align: start;
  padding-bottom: 10px;
  color: var(--text-color);
  font-weight: bold !important;
}

.scroll-intersection {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 12px; /* Larghezza dell'intersezione */
    height: 12px; /* Altezza dell'intersezione */
    background-color: #000; /* Colore nero per l'intersezione */
    z-index: 1; /* Assicurati che sia sopra lo scroll */
}