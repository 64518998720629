body {
  margin: 0;
  font-family: 'Custom_font', 'Ubuntu', monospace, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Roboto',
     'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variation-settings: 'opsz' 16 !important;
  background-color: var(--background-card);
}

@font-face {
  font-family: 'Custom_font';
  src: local('Ubuntu'), url(../public/font/Ubuntu-Regular.ttf) format('woff');
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px
}

::-webkit-scrollbar-track {
  background: var(--background-filter);
}

::-webkit-scrollbar-corner{
  background: var(--background-filter);
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-moz-scrollbar {
  width: 10px;
  height: 10px
}

::-moz-scrollbar-track {
  background: var(--background-filter);
}

::-moz-scrollbar-corner{
  background: var(--background-filter);
}

::-moz-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-moz-scrollbar-thumb:hover {
  background: #555;
}