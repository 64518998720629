/* Stile del contenitore footer */
.footer-container {
    margin-inline: -20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 20px;
    /*background-color: var(--background-card); */
    color: white;
    width: 100%;
}

/* Immagine nel footer */
.footer-image {
    width: 80%; /* Imposta la dimensione dell'immagine */
    height: auto;
}

/* Stile del bottone "Back to Top" */
.back-to-top-button {
    font-size: 5vh !important;
    background-color: var(--red-element) !important;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.back-to-top-button:hover {
    background-color: white !important;
    color: var(--red-element);
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  text-align: start;
  padding-block: 5px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: gray !important;
}