.header{
    padding-top: 20px;
}

.rtl-logo-container{
    position: relative;
    display: flex;
    justify-content: flex-start !important;
    align-items: center !important;
    width: 100%;
    height: 100% !important
}

.logo-container{
    position: relative;
    display: flex;
    justify-content: flex-end !important;
    align-items: center !important;
    width: 100%;
    height: 100% !important;
    padding-bottom: 5px;
}

.header-container{
    border-bottom: solid 5px red;
}

.title-container{
    display: flex;
    direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
}

.title{
    font-size: 3.2vw;
    font-weight: bold;
    color: var(--text-color)
}

.logout-button{
    padding-left: 16px;
    padding-block: 12px;
    line-height: 1.25;
    background-color: transparent !important;
    font-size: max(18px,1.0vw) !important; 
    color: white;
    border: none;
    cursor: pointer;
}

.logout-button:hover {
    color: var(--red-element);
}

.hello_tag{
    font-size: max(18px, 1.0vw) !important;
    font-family: 'Custom_font';
    color: white
}