:root {
  --background: rgba(0, 0, 0, 0.9);
  --background-score-card: rgba(0, 0, 0, 0.5);
  --text-color: white !important;
  --error: red;
  --background-filter: rgba(39, 42, 53, 1);
  --background-card: rgb(39, 42, 45, 1);
  --red-element: rgba(255, 0, 0, 255);
}

.App {
  text-align: center;
  background-color: var(--background-score-card);
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
