.overview-tab-section{
    text-align: left;
    font-size: max(1.8vw,20px) !important;
    color: var(--text-color)
}

.ter-tab-section{
    width: 100% !important;
    text-align: left;
    font-size: max(1.8vw,20px) !important;
    color: var(--text-color);
    background-color: var(--red-element);
    border: 2px solid var(--red-element);
    border-radius: 15px
}

.pulsing-dot {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}