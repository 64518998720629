.login-container {
    width: 100%;
    height: 100vh;
    background-color: var(--red-element); 
}    

.login-box {
    background-color: rgba(0, 0, 0, 0.8); /* Sfondo nero con opacità 0.8 */
    font-weight: bold;
    color: white;
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    width: 50%;
    font-size: 30px;
    max-width: 500px;
    min-width: 300px;
}

.dashboard-image {
    width: 150px; /* Imposta la larghezza desiderata dell'immagine */
    height: 150px; /* Imposta l'altezza desiderata dell'immagine */
}

.dashboard-text {
    font-size: 40px !important;
    vertical-align: middle;
}

.error-message {
    color: var(--red-element);
}

.login-button{
    max-width: 200px !important;
}